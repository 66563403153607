/* Import Tailwind base, components, and utilities */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom font-face */
@font-face {
	font-family: "Kollektif";
	/* Replace with the actual font name */
	src: url("https://flatsinkolhapur.com:5000/v1/public/fonts/Kollektif.ttf")
		format("truetype");
	font-weight: normal;
	font-style: normal;
}

/* Apply the custom font to the entire body */
body {
	font-family: "Kollektif";
}

/* Scrollbar styling specifically for horizontal scrollbar */
/* Webkit-based browsers */
.filter::-webkit-scrollbar {
	height: 0%;
	/* Set height for horizontal scrollbar */
}

.filter::-webkit-scrollbar-track {
	background: #f1f1f1;
}

.filter::-webkit-scrollbar-thumb {
	background: #5c5c5c;
}

.filter::-webkit-scrollbar-thumb:hover {
	background: #5c5c5c;
}

/* Add additional custom scrollbar styles for Firefox */
.filter {
	scrollbar-width: thin;
	/* Firefox specific */
	scrollbar-color: #5c5c5c #f1f1f1;
}

/* Scrollbar styling */
/* width */
::-webkit-scrollbar {
	width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #5c5c5c;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #5c5c5c;
}

input[type="range"] {
	/* Basic styling for the slider track */
	-webkit-appearance: none;
	height: 8px;
	background: #ddd;
	border-radius: 5px;
	outline: none;
}

/* WebKit browsers (Chrome, Safari) */
input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 30px; /* Increase the width of the circle */
	height: 30px; /* Increase the height of the circle */
	background: #5c5c5c;
	border-radius: 50%;
	cursor: pointer; /* Optional: Add border */
}

/* Firefox */
input[type="range"]::-moz-range-thumb {
	width: 30px; /* Increase the width of the circle */
	height: 30px; /* Increase the height of the circle */
	background: #5c5c5c;
	border-radius: 50%;
	cursor: pointer; /* Optional: Add border */
}



.range_container {
	display: flex;
	flex-direction: column;
	width: 80%;
	margin: 35% auto;
  }
  
  .sliders_control {
	position: relative;
	min-height: 50px;
  }
  
  .form_control {
	position: relative;
	display: flex;
	justify-content: space-between;
	font-size: 24px;
	color: #635a5a;
  }
  
  input[type=range]::-webkit-slider-thumb {
	-webkit-appearance: none;
	pointer-events: all;
	width: 24px;
	height: 24px;
	background-color: #fff;
	border-radius: 50%;
	box-shadow: 0 0 0 1px #C6C6C6;
	cursor: pointer;
  }
  
  input[type=range]::-moz-range-thumb {
	-webkit-appearance: none;
	pointer-events: all;
	width: 24px;
	height: 24px;
	background-color: #fff;
	border-radius: 50%;
	box-shadow: 0 0 0 1px #C6C6C6;
	cursor: pointer;  
  }
  
  input[type=range]::-webkit-slider-thumb:hover {
	background: #f7f7f7;
  }
  
  input[type=range]::-webkit-slider-thumb:active {
	box-shadow: inset 0 0 3px #387bbe, 0 0 9px #387bbe;
	-webkit-box-shadow: inset 0 0 3px #387bbe, 0 0 9px #387bbe;
  }
  
  input[type="number"] {
	color: #8a8383;
	width: 50px;
	height: 30px;
	font-size: 20px;
	border: none;
  }
  
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button {  
	 opacity: 1;
  }
  
  input[type="range"] {
	-webkit-appearance: none; 
	appearance: none;
	height: 4px;
	width: 280px;
	position: absolute;
	background-color: #C6C6C6;
	pointer-events: none;
	bottom:50px;
  }
  
  #fromSlider {
	height: 0;
	z-index: 1;
  }
  
  input[type=range].min-range {
	z-index: 10; /* Ensure min slider is on top */
  }
  
  input[type=range].max-range {
	z-index: 4; /* Ensure max slider is below the min slider */
  }
  


  /* Default styling for all screen sizes (mobile first) */
.custom-height {
	height: auto; /* Default height */
  }
  
  /* For medium screens and up (md breakpoint, min-width: 768px) */
  @media (max-width: 1440px) {
	.custom-height {
	  height: 880px; /* Set height to 910px for lg screens */
	}
  }
  /* For large screens and up (lg breakpoint, min-width: 1024px) */
  @media (max-width: 1404px) {
	.custom-height {
	  height: 860px; /* Set height to 910px for lg screens */
	}
  }
  @media (max-width: 1304px) {
	.custom-height {
	  height: 850px; /* Set height to 910px for lg screens */
	}
  }

  @media (max-width: 1280px) {
	.custom-height {
	  height: 770px; /* Set height to 910px for lg screens */
	}
  }

  @media (max-width: 1170px) {
	.custom-height {
	  height: 600px; /* Set height to 910px for lg screens */
	}
  }
  @media (max-width: 992px) {
	.custom-height {
	  height: 550px; /* Set height to 600px for md screens */
	}
  }
  @media (max-width: 840px) {
	.custom-height {
	  height: 500px; /* Set height to 600px for md screens */
	}
  }
  @media (max-width: 767px) {
	.custom-height {
	  height: auto; /* Set height to 600px for md screens */
	}
  }